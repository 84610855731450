<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from '@/services/Monitor';
import Swal from "sweetalert2";
import modalAddApplicationAccess from '@/components/modals/application/modalAddApplicationAccess';
import modalEditApplicationAccess from '@/components/modals/application/modalEditApplicationAccess';

export default {
    components: { Layout, PageHeader,  modalAddApplicationAccess, modalEditApplicationAccess},
    page: {
        title: "Tenant Application Access",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    created() {
        this.getTenants()
        this.getTenantApplications()
    },
    methods: {
        modalAddNewApplicationAccess(){
            this.$bvModal.show("add_new_application_access")
        },
        getTenants(){
            Monitor.getTenants()
                .then((response) => {
                    //console.log(response.data.tenants)
                    this.tenants = response.data.tenants
                })
                .catch(error => {
                })
        },
        getTenantApplications(){
            Monitor.getTenantApplications(this.currentTenant)
                .then((response) => {
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                })
                .catch(error => {
                    this.tableData = []
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        callmodalEditApplicationAccess(item){
            this.modalData = item
            this.$bvModal.show("edit_application_access")
        },
        deleteTenantApplication(id){
            Swal.fire({
                title: "Delete Application Access?",
                text: "Remove this Application Access",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Remove!",
            }).then((result) => {
                if (result.value) {
                    Monitor.deleteTenantApplication(id)
                          .then((response) => {
                              const res = response.data ? response.data : false;
                              const error = response.data.error ? response.data.error : 'Failed';
                              if(res){
                                  this.getTenantApplications()
                                  Swal.fire("Remove Application", "Application KEY successfully removed!", "success");
                              }else{
                                  Swal.fire("Fail!", error, "warning");
                              }
                          })
                          .catch(error => {
                              this.error = error.response.data.error ? error.response.data.error : "Delete Failed";
                              this.failedmsg(this.error)
                          })
                }
            });
        },
    },
    data() {
        return {
            title: "Tenant Application Access",
            items: [
                {
                    text: "Application",
                    href: "/application",
                },
                {
                    text: "Application Access",
                    active: true,
                },
            ],
            isBusy: false,
            error: null,
            showModal:false,
            modalData:{},
            link_id:null,
            tenants: [],
            currentTenant: 'MON',
            tableData: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "app_id",
            sortDesc: true,
            fields: [
                {
                    key: "app_id",
                    label: "App ID",
                    sortable: true,
                },
                {
                    key: "api_key",
                    label: "Api Key",
                    sortable: false,
                },
                {
                    key: "app_name",
                    label: "Name",
                    sortable: false,
                },
                {
                    key: "app_type",
                    label: "Type",
                    sortable: true,
                },
                {
                    key: "role_source",
                    label: "Role Source",
                    sortable: true,
                },
                {
                    key: "jwt_token",
                    label: "JWT Token",
                    sortable: true,
                },
                {
                    key: "jwt_expire",
                    label: "JWT Expire",
                    sortable: true,
                },
                {
                    key: "jwt_store",
                    label: "JWT Store",
                    sortable: true,
                },
                {
                    key: "forbid_public",
                    label: "Forbid Public",
                    sortable: true,
                },
                {
                    key: "auth_2fa",
                    label: "2FA",
                    sortable: true,
                },
                {
                    key: "csrf_token",
                    label: "CFRF Token",
                    sortable: true,
                },
                {
                    key: "recaptcha",
                    label: "Recaptcha",
                    sortable: true,
                },
                {
                    key: "return_refresh_token",
                    label: "Refresh Token",
                    sortable: true,
                },
                "edit",
                "delete",
            ],
        };
    },
    destroyed() {
    },
    middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
			<div class="row mt-2">
              <label class="col-md-1 col-form-label">Select Tenant</label>
              <div class="col-md-3">
                <div class="form-row form-group mb-3">
                  <select class="form-select" v-model="currentTenant" @change="getTenantApplications()" >
                    <option v-for="tenant in tenants" :key="tenant" :value="tenant">{{tenant}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="card">
          <div class="card-body">

            <h4 class="card-title">Tenant Applications: {{ currentTenant }}</h4>

            <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                    <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddNewApplicationAccess">
                        <i class="mdi mdi-plus me-2"></i> 
                        Add New Application Access
                    </a>
                </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
          </div>


          <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive mb-0">
                  <b-table
                    :busy="isBusy"
                    :items="tableData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    show-empty
                    empty-text="No Data Found"
                    @filtered="onFiltered"
                  >

                  <template v-slot:cell(api_key)="data">
                    <span class='badge bg-success font-size-14'>{{data.item.api_key}}</span>
                  </template>

                    <template v-slot:cell(edit)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callmodalEditApplicationAccess(data.item)">
                                        <i class="uil uil-pen font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template v-slot:cell(delete)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove" @click="deleteTenantApplication(data.item.id)">
                                    <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                  </b-table>

                </div>
              </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                            ></b-pagination>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

      </div>
	</div>

  <!-- MODALS -->
  <modalAddApplicationAccess  @onRefresh="getTenantApplications" :tenant="currentTenant"></modalAddApplicationAccess>
  <modalEditApplicationAccess @onRefresh="getTenantApplications" :modalData="modalData" :tenant="currentTenant"></modalEditApplicationAccess>
  <!-- /MODALS -->


  </Layout>
</template>
