<script>
    import Monitor from '@/services/Monitor'
    import { required, minLength, numeric } from "vuelidate/lib/validators"


    export default {
        components:{
        },
        props: {
            modalData: {
                type: Object,
                default: null
            },
            tenant: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                csrf_token: localStorage.getItem('csrf_token'),
                app_type_options: ['INTERNAL','EXTERNAL'],
                app_platform_options:['web','mobile','server'],
                role_source_options:['crm_user','app_users'],
                jwt_token_options:[1,0],
                jwt_store_options:[1,0],
                forbid_public_options:[1,0],
                endpoints_access_options:['all','limited'],
                return_refresh_token_options:[1,0],
                auth_2fa_options:['required','optional','none'],
                csrf_token_options:[1,0],
                recaptcha_options:[1,0],
                application: {
                    app_name: '',
                    app_type: 'INTERNAL',
                    app_platform: 'web',
                    role_source: 'crm_user',
                    jwt_token: 0,
                    jwt_expire: 0,
                    jwt_store: 0,
                    forbid_public: 0,
                    endpoints_access: 'all',
                    return_refresh_token: 1,
                    auth_2fa: 'none',
                    csrf_token: 0,
                    recaptcha: 0,
                },
            };
        },
        
        methods: {
            shownModal(){
                this.syncData()
            },
            syncData(){
                //console.log(this.popupsData)
                this.application.app_name = this.modalData.app_name
                this.application.app_type = this.modalData.app_type
                this.application.app_platform = this.modalData.app_platform
                this.application.role_source = this.modalData.role_source
                this.application.jwt_token = this.modalData.jwt_token
                this.application.jwt_expire = this.modalData.jwt_expire
                this.application.jwt_store = this.modalData.jwt_store
                this.application.forbid_public = this.modalData.forbid_public
                this.application.endpoints_access = this.modalData.endpoints_access
                this.application.return_refresh_token = this.modalData.return_refresh_token
                this.application.auth_2fa = this.modalData.auth_2fa
                this.application.csrf_token = this.modalData.csrf_token
                this.application.recaptcha = this.modalData.recaptcha
            },
            updateTenantApplication(){

                this.tryingToSubmit = true
                this.submitted = true

                let formData = new FormData();
                formData.append('app_name', this.application.app_name)
                formData.append('app_type', this.application.app_type)
                formData.append('app_platform', this.application.app_platform)
                formData.append('default_role_name', this.application.role_source)
                formData.append('role_source', this.application.role_source)
                formData.append('jwt_token', this.application.jwt_token)
                formData.append('jwt_expire', this.application.jwt_expire)
                formData.append('jwt_store', this.application.jwt_store)
                formData.append('forbid_public', this.application.forbid_public)
                formData.append('endpoints_access', this.application.endpoints_access)
                formData.append('return_refresh_token', this.application.return_refresh_token)
                formData.append('auth_2fa', this.application.auth_2fa)
                formData.append('csrf_token', this.application.csrf_token)
                formData.append('recaptcha', this.application.recaptcha)
                formData.append('tenant', this.tenant)
                formData.append('_method', 'PATCH')

                Monitor.updateTenantApplication(this.modalData.app_id, formData)
                    .then((response) => {
                        const res = response.data.data ? response.data.data : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res){
                            this.successmsg('Application Updated!');
                            this.refreshData();
                            this.closeModal();
                        }else{
                            this.failedmsg('Edit Failed!')
                        }
                    })
                    .catch(error => {
                        const resData = error.response.data.error ? error.response.data.error : 'Failed';
                        const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                        this.failedmsg(errorMsg)
                    })
                    .finally(() => {
                        this.tryingToSubmit = false
                    })

            },
            resetProps(){
                this.submitted = false
            },
            closeModal() {
                this.showModal = false
            },
            refreshData() { 
                this.$emit('onRefresh') //event from parent
            },
        },
    };
</script>

<template>
    <b-modal  @shown="shownModal()" id="edit_application_access" size="md" v-model="showModal" @hidden="resetProps" title="Edit Marketing Popup" title-class="font-18">
    <form @submit.prevent="updateTenantApplication">
        <div class="row">
          <div class="col-12">
                
            <div class="mb-3">
                    <label class="control-label form-label">Application Name *</label>
                    <b-form-input v-model="application.app_name"></b-form-input>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Application Type</label>
                    <select v-model="application.app_type" class="form-control form-select" name="option" type="select">
                        <option v-for="option in app_type_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Application Platform</label>
                    <select v-model="application.app_platform" class="form-control form-select" name="option" type="select">
                        <option v-for="option in app_platform_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Role Source</label>
                    <select v-model="application.role_source" class="form-control form-select" name="option" type="select">
                        <option v-for="option in role_source_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">JWT Token</label>
                    <select v-model="application.jwt_token" class="form-control form-select" name="option" type="select">
                        <option v-for="option in jwt_token_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">JWT Expire (*seconds)</label>
                    <b-form-input v-model="application.jwt_expire"></b-form-input>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">JWT Store</label>
                    <select v-model="application.jwt_store" class="form-control form-select" name="option" type="select">
                        <option v-for="option in jwt_store_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Forbid Public Routes</label>
                    <select v-model="application.forbid_public" class="form-control form-select" name="option" type="select">
                        <option v-for="option in forbid_public_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">2FA (2 Factor Auth)</label>
                    <select v-model="application.auth_2fa" class="form-control form-select" name="option" type="select">
                        <option v-for="option in auth_2fa_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">CSRF Token</label>
                    <select v-model="application.csrf_token" class="form-control form-select" name="option" type="select">
                        <option v-for="option in csrf_token_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Return Refresh JWT Token</label>
                    <select v-model="application.return_refresh_token" class="form-control form-select" name="option" type="select">
                        <option v-for="option in return_refresh_token_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Recaptcha</label>
                    <select v-model="application.recaptcha" class="form-control form-select" name="option" type="select">
                        <option v-for="option in recaptcha_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>

              

          </div>
        </div>

        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="updateTenantApplication" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Change
            </b-button>
        </template>

    </b-modal>
 
</template>