<script>
    import validationMessages from '@/components/validations'
    import { required, minLength, numeric } from "vuelidate/lib/validators"
    import moment from "moment"
    import Monitor from '@/services/Monitor'


    export default {
        components:{
            //validationMessages,
        },
        props: {
            tenant: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                app_type_options: ['INTERNAL','EXTERNAL'],
                app_platform_options:['web','mobile','server'],
                role_source_options:['crm_user','app_users'],
                jwt_token_options:[1,0],
                jwt_store_options:[1,0],
                forbid_public_options:[1,0],
                endpoints_access_options:['all','limited'],
                return_refresh_token_options:[1,0],
                auth_2fa_options:['required','optional','none'],
                csrf_token_options:[1,0],
                recaptcha_options:[1,0],
                application: {
                    app_name: '',
                    app_type: 'INTERNAL',
                    app_platform: 'web',
                    role_source: 'crm_user',
                    jwt_token: 0,
                    jwt_expire: 0,
                    jwt_store: 0,
                    forbid_public: 0,
                    endpoints_access: 'all',
                    return_refresh_token: 1,
                    auth_2fa: 'none',
                    csrf_token: 0,
                    recaptcha: 0,
                },
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        validations: {

        },
        methods: {
            refreshData() { 
                this.$emit('onRefresh') //event from parent
            },
            closeModal() {
                this.showModal = false
            },
            resetProps(){
                this.submitted = false
                this.application.app_name = ''
                this.application.app_type = 'INTERNAL'
                this.application.app_platform = 'web'
                this.application.role_source = 'crm_user'
                this.application.jwt_token = 0
                this.application.jwt_expire = 0
                this.application.jwt_store = 0
                this.application.forbid_public = 0
                this.application.endpoints_access = 'all'
                this.application.return_refresh_token = 1
                this.application.auth_2fa = 0
                this.application.csrf_token = 0
                this.application.recaptcha = 0
            },

            createApplicationAccess(){

                this.tryingToSubmit = true
                this.submitted = true

                let formData = new FormData();
                formData.append('app_name', this.application.app_name)
                formData.append('app_type', this.application.app_type)
                formData.append('app_platform', this.application.app_platform)
                formData.append('default_role_name', this.application.role_source)
                formData.append('role_source', this.application.role_source)
                formData.append('jwt_token', this.application.jwt_token)
                formData.append('jwt_expire', this.application.jwt_expire)
                formData.append('jwt_store', this.application.jwt_store)
                formData.append('forbid_public', this.application.forbid_public)
                formData.append('endpoints_access', this.application.endpoints_access)
                formData.append('return_refresh_token', this.application.return_refresh_token)
                formData.append('auth_2fa', this.application.auth_2fa)
                formData.append('csrf_token', this.application.csrf_token)
                formData.append('recaptcha', this.application.recaptcha)
                formData.append('tenant', this.tenant)

                Monitor.createTenantApplication(formData)
                    .then((response) => {
                        const res = response.data.data ? response.data.data : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res){
                            this.successmsg('Application Key Created!');
                            this.refreshData();
                            this.closeModal();
                        }else{
                            this.failedmsg('Failed to create new Application Key!')
                        }
                    })
                    .catch(error => {
                        const resData = error.response.data.error ? error.response.data.error : 'Failed';
                        const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                        this.failedmsg(errorMsg)
                    })
                    .finally(() => {
                        this.tryingToSubmit = false
                    })
                
            },
        }
    };
</script>

<template>
    <b-modal  id="add_new_application_access" size="md" v-model="showModal" @hidden="resetProps" title="Add New Application Aceess" title-class="font-18">
    <form @submit.prevent="createApplicationAccess">
        <div class="row">
          <div class="col-12">

                <div class="mb-3">
                    <label class="control-label form-label">Application Name *</label>
                    <b-form-input v-model="application.app_name"></b-form-input>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Application Type</label>
                    <select v-model="application.app_type" class="form-control form-select" name="option" type="select">
                        <option v-for="option in app_type_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Application Platform</label>
                    <select v-model="application.app_platform" class="form-control form-select" name="option" type="select">
                        <option v-for="option in app_platform_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Role Source</label>
                    <select v-model="application.role_source" class="form-control form-select" name="option" type="select">
                        <option v-for="option in role_source_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">JWT Token</label>
                    <select v-model="application.jwt_token" class="form-control form-select" name="option" type="select">
                        <option v-for="option in jwt_token_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">JWT Expire (*seconds)</label>
                    <b-form-input v-model="application.jwt_expire"></b-form-input>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">JWT Store</label>
                    <select v-model="application.jwt_store" class="form-control form-select" name="option" type="select">
                        <option v-for="option in jwt_store_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Forbid Public Routes</label>
                    <select v-model="application.forbid_public" class="form-control form-select" name="option" type="select">
                        <option v-for="option in forbid_public_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">2FA (2 Factor Auth)</label>
                    <select v-model="application.auth_2fa" class="form-control form-select" name="option" type="select">
                        <option v-for="option in auth_2fa_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">CSRF Token</label>
                    <select v-model="application.csrf_token" class="form-control form-select" name="option" type="select">
                        <option v-for="option in csrf_token_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Return Refresh JWT Token</label>
                    <select v-model="application.return_refresh_token" class="form-control form-select" name="option" type="select">
                        <option v-for="option in return_refresh_token_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="control-label form-label">Recaptcha</label>
                    <select v-model="application.recaptcha" class="form-control form-select" name="option" type="select">
                        <option v-for="option in recaptcha_options" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>

          </div>
        </div>

        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createApplicationAccess" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>

    </b-modal>
 
</template>